<template>
  <div>
    <base-page-title :titleText="pageTitle" />
    <client-form
      :formType="formType"
      :client="client"
      @setClient="createClientAccount"
    />
  </div>
</template>

<script>
import ClientForm from "./../components/Client/ClientForm";
export default {
  components: {
    ClientForm
  },
  data() {
    return {
      pageTitle: "account.new.client",
      formType: "NEW",
      client: {
        companyName: "",
        firstName: "",
        lastName: "",
        // registration: {
        //   email: ""
        // },
        email: "",
        country: "",
        phone: "",
        jobTitle: "",
        address: "",
        city: "",
        province: "",
        zip: "",
        vat: "",
        sdlCode: "", // NOT USED
        pec: "", // NOT USED
        type: "client",
        clientType: "custom",
        multiUser: false,
        newsletterRegistration: false,
        agreeToTerms: false,
        branches: []
      }
    };
  },
  methods: {
    createClientAccount(client) {
      this.$store.commit("loader/SET_LOADER", {});
      this.$store
        .dispatch("clients/createClient", client)
        .catch(() => {})
        .finally(() => this.$store.dispatch("loader/close"));
    }
  }
};
</script>

<style></style>
